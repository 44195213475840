<template>
  <div id="app">
    <SomeThingWarn/>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="info">

        <b-navbar-brand :to="{name: 'home'}">
          國立臺東大學學生宿舍系統
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"/>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item-dropdown
              v-if="isLogin"
              right
              text="個人專區"
              toggle-class="nav-link-custom"
            >
              <b-dropdown-item :to="{name: 'student_area_home'}">入住報到</b-dropdown-item>
              <b-dropdown-item :to="{name: 'student_area_check_in_form'}">期初報到單</b-dropdown-item>
              <b-dropdown-item :to="{name: 'borrow_apply'}">申請借用物品</b-dropdown-item>
              <b-dropdown-divider v-if="tags.includes('測試者')"/>
              <b-dropdown-item v-if="tags.includes('測試者')" :to="{name: 'student_area_curfew_exempt_apply'}">晚歸申請（施工中）
              </b-dropdown-item>
              <b-dropdown-item v-if="tags.includes('測試者')" :to="{name: 'student_area_package'}">包裹信件查詢（施工中）
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav>
            <b-nav-item-dropdown
              v-if="policies.includes('最高權限')"
              right
              text="行政人員區"
              toggle-class="nav-link-custom"
            >
              <b-dropdown-item :to="{name: 'adminRoot'}">宿舍概覽</b-dropdown-item>
              <b-dropdown-divider/>
              <b-dropdown-item :to="{name: 'partTimeCheckIn'}">學生報到</b-dropdown-item>
              <b-dropdown-item :to="{name: 'borrowFloorMaster'}">物品借用</b-dropdown-item>
              <b-dropdown-item :to="{name: 'ModifyLoanItem'}">借用查詢</b-dropdown-item>
              <b-dropdown-divider/>
              <b-dropdown-item :to="{name: 'roomManagement'}">寢室管理</b-dropdown-item>
              <b-dropdown-item v-if="tags.includes('測試者')" :to="{name: 'adminCurfewExemptCheck'}">晚歸管理</b-dropdown-item>
              <b-dropdown-item :to="{name: 'adminCustomerManagement'}">報到資料管理</b-dropdown-item>
              <b-dropdown-item :to="{name: 'userManagement'}">學生資料管理</b-dropdown-item>
              <b-dropdown-item :to="{name: 'TagManagement'}">學生標籤管理</b-dropdown-item>
              <b-dropdown-item :to="{name: 'studentCheckinForm'}">期初報到單管理</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <b-navbar-nav>
            <b-nav-item-dropdown
              v-if="policies.includes('工讀生')"
              right
              text="工讀生專區"
              toggle-class="nav-link-custom"
            >
              <b-dropdown-item :to="{name: 'partTimeFindStudent'}">查詢住宿生基本資料</b-dropdown-item>
              <b-dropdown-divider/>
              <b-dropdown-item :to="{name: 'partTimeCheckIn'}">學生報到</b-dropdown-item>
              <b-dropdown-item :to="{name: 'borrowFloorMaster'}">物品借用</b-dropdown-item>
              <b-dropdown-item :to="{name: 'borrowManage'}">借用查詢</b-dropdown-item>
              <b-dropdown-item :to="{name: 'COVID-19-Guard'}">武漢肺炎</b-dropdown-item>
              <b-dropdown-item v-if="tags.includes('測試者')" :to="{name: 'partTimePackageReg'}">包裹登記（未開放）
              </b-dropdown-item>
              <b-dropdown-item v-if="tags.includes('測試者')" :to="{name: 'partTimeMailTakePackage'}">包裹領取（未開放）
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav>
            <b-nav-item-dropdown
              v-if="policies.includes('樓長')"
              right
              text="樓長專區"
              toggle-class="nav-link-custom"
            >
              <b-dropdown-item :to="{name: 'floorMasterAreaCheckForm'}">期初報到單審核</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav>
            <b-nav-item-dropdown
              v-if="policies.includes('宿舍')"
              right
              text="宿委會值班"
              toggle-class="nav-link-custom"
            >
              <b-dropdown-item :to="{name: 'partTimeCheckIn'}">學生報到</b-dropdown-item>
              <b-dropdown-item :to="{name: 'borrowFloorMaster'}">物品借用</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <!--<b-nav-form>
              <b-form-input size="sm" class="mr-sm-2" placeholder="Search"/>
              <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
            </b-nav-form>

            <b-nav-item-dropdown text="Lang" right>
              <b-dropdown-item href="#">EN</b-dropdown-item>
              <b-dropdown-item href="#">ES</b-dropdown-item>
              <b-dropdown-item href="#">RU</b-dropdown-item>
              <b-dropdown-item href="#">FA</b-dropdown-item>
            </b-nav-item-dropdown>-->

            <b-nav-item-dropdown right>
              <template v-slot:button-content>
                你好，{{ userName }}
              </template>
              <b-dropdown-item v-on:click="verifyStatus">重新載入帳戶資料</b-dropdown-item>
              <b-dropdown-divider/>
              <b-dropdown-item :to="{name: 'home'}">首頁</b-dropdown-item>
              <b-dropdown-item v-if="isLogin" v-on:click="logout">登出</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <div class="container-fluid">
      <router-view/>
    </div>
  </div>
</template>
<script>
import SomeThingWarn from "@/components/SomeThingWarn";

export default {
  name: 'top',
  components: {
    SomeThingWarn
  },
  mounted: function () {
    if (this.$cookies.isKey("dorm_jwt")) {
      this.$store.dispatch("verifyStatus");
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.userState.isLogin;
    },
    isAdmin() {
      return this.$store.state.userState.isAdmin;
    },
    userName() {
      return this.$store.state.userState.name;
    },
    policies() {
      return this.$store.state.userState.policies;
    },
    tags() {
      return this.$store.state.userState.tags;
    }
  },
  methods: {
    logout: function () {
      this.$store.commit('resetUserStatus');
      this.$router.push({name: "home"});
    },
    verifyStatus: function () {
      this.$store.dispatch('verifyStatus');
    }
  },
};
</script>
