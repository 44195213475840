import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'


Vue.use(Vuex);


const userState = {
    state: {
        isLogin: false,
        isAdmin: false,
        isTenant: false,
        uuid: "",
        name: "未登入的使用者",
        policies: [],
        tags: [],
        BedHistories: []
    },
    mutations: {
        resetUserStatus(state){
            state.isLogin = false;
            state.isAdmin = false;
            state.uuid = "";
            state.name = "未登入的使用者";
            state.tags = [];
            state.BedHistories = [];
            if(Vue.$cookies.isKey("dorm_jwt")){
                Vue.$cookies.remove("dorm_jwt");
            }
            state.policies = [];
        },
        setUserStatus(state, payload){
            state.isLogin = true;
            state.isAdmin = payload.isAdmin;
            state.uuid = payload.uuid;
            state.name = payload.name;
            state.policies = payload.policies;
            state.tags = payload.tags
            state.activeBedHistories = payload.active_bed_history;
        }

    },
    actions: {
        verifyStatus(context){
            // 預計是要去Call 一次API來確定當前之登入狀態，非同步
            // 正常是要收到401後要確認的
            axios.get('/api/auth/verify')
                .then(function (response) {
                    context.commit("setUserStatus", response.data);
                })
                .catch(function (error) {
                    if(error.response.status === 401){
                        context.commit("resetUserStatus");
                    }else if(error.response.status === 503) {
                        context.commit("errorHandle/raiseError", error.response.data.msg);
                    }else{
                        if('msg' in error.response.data){
                            context.commit("errorHandle/raiseError", error.response.data.msg);
                        }else{
                            context.commit("errorHandle/raiseError", error.message);
                        }
                    }
                })
        },
        async syncToken(context){
            // 目前好像還沒用到，此函數目的是在跳轉頁面或是其他事情時以非阻塞方式驗證Token
            if (!Vue.$cookies.isKey("dorm_jwt")){
                context.commit('resetUserStatus')
            }else{
                await axios.get('/api/auth/verify')
                    .then(response => (context.commit('setUserStatus', response.data)))
                    .catch(function (error) {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            window.console.log(error.response.data);
                            window.console.log(error.response.status);
                            window.console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            window.console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            window.console.log('Error', error.message);
                        }
                    })
            }

        }

    },
    getters: {

    }
};

const errorHandle = {
    namespaced: true,
    state:{
        isError: false,
        errorMsg: ""
    },
    mutations:{
        reset(state){
            state.isError = false;
            state.errorMsg = "";
        },
        raiseError(state, errorMsg){
            state.isError = true;
            if (errorMsg === undefined){
                state.errorMsg = "";
            }else {
                state.errorMsg = errorMsg;
            }
        }
    },
    actions:{},
    getters:{}
};

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        userState: userState,
        errorHandle: errorHandle
    },
    strict: process.env.NODE_ENV !== 'production'
})