import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from "axios";
import store from '@/store/index'

Vue.use(VueRouter);


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "group-homepage" */ '../views/Home')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/student',
    name: "student_area_home",
    children: [
      {
        path: 'email',
        name: "student_area_email",
        component: () => import(/* webpackChunkName: "group-student" */ '../views/studentArea/Email')
      },
      {
        path: 'phone',
        name: "student_area_phone",
        component: () => import(/* webpackChunkName: "group-student" */ '../views/studentArea/Phone')
      },
      {
        path: 'option',
        name: "student_area_option",
        component: () => import(/* webpackChunkName: "group-student" */ '../views/studentArea/Option')
      },
      {
        path: 'CurfewExemptApply',
        name: 'student_area_curfew_exempt_apply',
        component: () => import('../views/studentArea/CurfewExemptApply'),
        beforeEnter: function (to, from, next) {
          next(store.state.userState.tags.includes("測試者"));
        }
      },
      {
        path: 'checkInForm',
        name: 'student_area_check_in_form',
        component: () => import('../views/studentArea/checkForm'),
        beforeEnter: function (to, from, next) {
          next(true);
        },
      },
      {
        path: 'borrowApply',
        name: 'borrow_apply',
        component: () => import('../views/studentArea/BorrowApply'),
        beforeEnter: function (to, from, next) {
          next(true);
        },
      },
      {
        path: 'package',
        name: 'student_area_package',
        beforeEnter: function (to, from, next) {
          next(false);
        },
        component: () => import('../views/studentArea/Package')
      }
    ],
    component: () => import(/* webpackChunkName: "group-student" */ '../views/studentArea/StudentAreaHome'),
    beforeEnter: (to, form, next) => {
      next((vm) => {
        return !!vm.$store.state.userState.isLogin;
      })

    }
  },
  {
    path: '/admin',
    name: "adminRoot",
    component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/Home'),
    children: [
      {
        path: 'CustomerManagement',
        name: "adminCustomerManagement",
        component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/CustomerManagement'),
        children: [
          {
            path: 'upload',
            name: "adminCMUpload",
            component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/CustomerManagement/CM-Upload')
          },
          {
            path: 'manager',
            name: "adminCMManger",
            component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/CustomerManagement/CM-Manager')
          },
          {
            path: 'add',
            name: "adminCMAdd",
            component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/CustomerManagement/CM-Add')
          },
          {
            path: "BedHumanProblem",
            name: 'adminBedHumanProblem',
            component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/CustomerManagement/CM-BedHumanProblem')
          },
          {
            path: 'Payment',
            name: 'adminPayment',
            component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/CustomerManagement/CM-Payment')
          }
        ]
      },
      {
        path: 'user',
        name: 'userManagement',
        component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/userManagement'),
        children: [
          {
            path: ':uuid',
            name: 'userManagementDetails',
            component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/UserManagement/UD-Home'),
            redirect: {name: 'UD-Info'},
            children: [
              {
                path: 'info',
                name: 'UD-Info',
                component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/UserManagement/UD-Info'),
              },
              {
                path: "history",
                name: "UD-History",
                component: () => import('../views/AdminArea/UserManagement/UD-History')
              },
              {
                path: 'PayLodgingMatch',
                name: 'UD-PayLodgingMatch',
                component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/UserManagement/UD-PayLodgingMatch'),
              },
              {
                path: 'Borrow',
                name: 'UD-Borrow',
                component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/UserManagement/UD-Borrow'),
              },
              {
                path: 'CurfewExemptRecord',
                name: 'UD-CurfewExemptRecord',
                component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/UserManagement/UD-CurfewExemptRecord'),
              }
            ]
          }
        ]
      },
      {
        path: 'form',
        name: 'studentCheckinForm',
        component: () => import('../views/AdminArea/checkFormManagement'),
        beforeEnter: function (to, from, next) {
          next(true);
        },
      },
      {
        path: 'room',
        name: 'roomManagement',
        component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/RoomManagement'),
        children: [
          {
            path: ':room_id',
            name: 'roomManagementDetails',
            component: () => import('../views/AdminArea/RoomManagement/roomDetails')
          }
        ]
      },
      {
        path: "TagManagement",
        name: "TagManagement",
        component: () => import('../views/AdminArea/TagManagement')
      },
      {
        path: 'CurfewExemptCheck',
        name: 'adminCurfewExemptCheck',
        component: () => import('../views/AdminArea/CurfewExemptCheck'),
      },
      {
        path: 'modify-loan-item',
        name: 'ModifyLoanItem',
        component: () => import(/* webpackChunkName: "group-admin" */ '../views/AdminArea/AdminModifyLoanItem')
      },
    ]
  },
  {
    name: "partTime",
    path: '/partTime',
    component: () => import(/* webpackChunkName: "group-partTime" */ '../views/PartTime/Home'),
    children: [
      {
        path: 'checkin',
        name: 'partTimeCheckIn',
        component: () => import(/* webpackChunkName: "group-partTime" */ '../views/PartTime/CheckIn')
      },
      {
        path: 'borrowManage',
        name: 'borrowManage',
        component: () => import(/* webpackChunkName: "group-partTime" */ '../views/PartTime/borrowManage')
      },
      {
        path: 'package',
        name: "partTimePackageReg",
        component: () => import('../views/PartTime/Package')
      },
      {
        path: 'TakePackage',
        name: "partTimeMailTakePackage",
        component: () => import('../views/PartTime/TakePackage')
      },
      {
        path: "findStudent",
        name: "partTimeFindStudent",
        component: () => import('../views/PartTime/findStudent')
      },
      {
        path: "COVID-19",
        name: "COVID-19-Guard",
        component: () => import('../views/PartTime/COVID-19-Guard')
      }
    ]
  },
  {
    name: "floorMaster",
    path: "/floorMaster",
    children: [
      {
        path: 'floorMasterAreaCheckForm',
        name: 'floorMasterAreaCheckForm',
        component: () => import('../views/floorMasterArea/floorMasterAreaCheckForm'),
        beforeEnter: function (to, from, next) {
          next(true);
        },
      },
      {
        path: 'borrowFloorMaster',
        name: 'borrowFloorMaster',
        component: () => import('../views/floorMasterArea/borrowFloorMaster'),
        beforeEnter: function (to, from, next) {
          next(true);
        },
      },
    ],
    component: () => import('../views/floorMasterArea/Home')
  },
  {
    path: '/setting',
    name: 'setting',
    redirect: {name: 'functionSwitch'},
    component: () => import('../views/Setting/SettingHome'),
    children: [
      {
        path: 'functionSwitch',
        name: 'functionSwitch',
        component: () => import('../views/Setting/FunctionSwitch')
      }
    ]
  },
  {
    path: '*',
    name: '404Router',
    component: () => import('../views/PageNotFound404')
  }
];

const router = new VueRouter({
  routes
});


router.beforeEach((to, from, next) => {
  if (to.name === 'home') {
    next();
  } else if (from.name !== 'home') {
    axios.get('/api/auth/verify')
    .then(() => {
      next();
    }).catch(() => {
      next({name: 'home'});
    });
  } else {
    next();
  }
});

export default router
