<template>
    <div>
        <b-modal
                id="modal-SomeThingWarn"
                title="有什麼事情發生了"
                v-on:hidden="eventHidden"
        >
            <p class="my-4">我們好像遇到了一些困難…<br>
                這可能是一個小問題，不用理他也可以<br>
                但也可能導致你之後做的事情發生錯誤。<br>
                保險起見，你可以按下『登出並重新來過』，或關掉這個提示。<br>
                錯誤訊息：{{errorMsg}}</p>
            <template v-slot:modal-footer>
                <b-button v-on:click="logout" id="SomeThingWarn-logout-button" >登出並重新來過</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "SomeThingWarn",
        computed: mapState({
            isError: state => state.errorHandle.isError,
            errorMsg: state => state.errorHandle.errorMsg,
        }),
        methods: {
            logout: function () {
                this.$store.commit('resetUserStatus');
                this.$bvModal.hide("modal-SomeThingWarn");
                window.location = '/';
            },
            eventHidden: function () {
                if (this.isError){
                    this.$store.commit('errorHandle/reset');
                }
            }
        },
        watch:{
            // eslint-disable-next-line no-unused-vars
            isError: function (newValue) {
                if (newValue){
                    this.$bvModal.show("modal-SomeThingWarn");
                }else {
                    this.$bvModal.hide("modal-SomeThingWarn");
                }
            }
        }
    }
</script>

<style scoped>

</style>