import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import firebase from 'firebase/app';
import 'firebase/auth'
import VueCookies from 'vue-cookies'
import axios from "axios";

let firebaseConfig = {
  apiKey: "AIzaSyDYbrzwQDDqYHs7BoEicGOYQXpJWab368I",
  authDomain: "dorm-nttu-2c5bd.firebaseapp.com",
  databaseURL: "https://dorm-nttu-2c5bd.firebaseio.com",
  projectId: "dorm-nttu-2c5bd",
  storageBucket: "dorm-nttu-2c5bd.appspot.com",
  messagingSenderId: "811510533656",
  appId: "1:811510533656:web:f34bafb7db0b02f07a7d6d"
};

Vue.prototype.$firebase = firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueCookies);
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if(error.response && 401 === error.response.status){
    store.commit("resetUserStatus");
    window.alert("登入時間過長，您已被登出");
    router.push({name: 'home'}).catch(()=>{});
  }else {
    return Promise.reject(error);
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
